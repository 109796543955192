import jQuery, { ajaxSetup } from 'jquery';

// 状態管理クラス
import { States } from './states.js';

// 現在座標を管理するクラス
import { CurrentLocationListener } from './current_location_listener.js';

// 画面上の現在位置と地図の座標を管理するクラス
import { MapLocationListener } from './map_location_listener.js';

// 現在位置を監視し変更があったら現在座標を管理するクラスを更新する関数
import { watchCurrentPosition } from './watch_current_position.js';
// マップの中心位置を監視し変更があったら店舗を更新する関数
import { watchMapCurrentPosition } from './watch_map_current_position.js';

// ショップページ用簡易マップ
import { shopPageMap } from './shop_page_map.js';

// 地図を表示するクラス
import { Map } from './map.js';

// マーカー保存用
import { MarkerObj } from './marker.js';

import 'bootstrap';

/**
 * 現在位置保存
 */
class Current {
  constructor() {
    this._cp = '';
  }
  set cp(val) {
    this._cp = val;
  }
  get cp() {
    return this._cp;
  }
}

async function main() {
  // コンフィグレーション
  const states = new States();
  await states.checkUpdateTime();
  await states.importShopIcons();

  // 現在表示しているページの識別
  const page = document.querySelector('#page').value;

  // マップページだったら地図を表示
  if (page == 'map') {
    // 座標が指定されて呼ばれた
    const set_lat = document.querySelector('#lat').value;
    const set_lng = document.querySelector('#lng').value;
    const set_zoom = document.querySelector('#zoom').value;
    let start_location = null;
    if (set_lat && set_lng) {
      start_location = { lat: set_lat, lng: set_lng };
    }

    const current = new Current();
    const map_location_listener = new MapLocationListener(current, states);
    await watchMapCurrentPosition(map_location_listener);
    const markers = new MarkerObj();
    const map = new Map();
    if (start_location) {
      map.draw_map(map_location_listener, markers, start_location);
    } else {
      map.draw_map(map_location_listener, markers, map_location_listener.get_current_location());
    }
  } else if (page == 'shop') {
    const current_listener = new CurrentLocationListener();
    await watchCurrentPosition(current_listener);
    shopPageMap();
  } else {
    const current_listener = new CurrentLocationListener();
    await watchCurrentPosition(current_listener);
  }
}

main();
